<template>
  <!--
    Used to display the unrolled configuration of a device.
  -->
  <div class="deviceDetailUnrolledConfiguration">
    <pre>
      <template v-for="(line, index) in parsedConfiguration">
        <span
          :key="`deviceDetailSmConfiguration-lineSpan-${ index }`"
          :class="colorClassOfLine(line)"
>{{ line }}</span>
      </template>
    </pre>
  </div>
</template>

<script>
export default {
  name: 'DeviceDetailUnrolledConfiguration',
  props: {
    unrolledConfiguration: {
      type: String,
      required: true
    }
  },
  computed: {
    parsedConfiguration: function () {
      return this.unrolledConfiguration.split("\n");
    }
  },
  methods: {
    colorClassOfLine (line) {
      let trimmedLine = line.trim();
      if (!trimmedLine) {
        return 'd-none';
      }
      if (!trimmedLine.length) {
        return 'd-none';
      }
      if (trimmedLine.length == 0) {
        return 'd-none';
      }
      if (trimmedLine.charAt(0) === '#') {
        return 'lighterGray'
      }
    }
  }
}
</script>

<style scoped>
pre {
  white-space: normal;
}
span {
  display: block;
  min-height: 17px;
}
</style>
